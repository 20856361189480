import { CustomerInvoicingMode } from '@/login/loginModel';
import * as axios from 'axios';

const url = (userId: string, action: string) => `${process.env.VUE_APP_API_URL}/${userId}/${action}`;

export interface SaveBasicAssistantDataModel {
    hourlyRate: number;
    firstName: string,
    lastName: string,
    gender?: Gender,
    maritalStatus?: MaritalStatus,
    height?: number,
    description: string;
    birthDate?: Date;
    educationLevel?: EducationLevel;
    educationDetails?: string;
}

export enum Gender {
    Female,
    Male
}

export enum MaritalStatus {
    Single,
    Relationship,
    Engaged,
    Married,
    OpenRelationship,
    Complicated,
    Separated,
    Divorced,
    NotAvailable,
}

export enum EducationLevel {
    Higher,
    Student,
    Other
}

export enum LanguageLevel {
    Basic,
    Intermediate,
    Advanced,
    Native,
    None = 999,
}

export interface UserLanguage {
    languageCode: string;
    level: LanguageLevel;
}

export interface SaveLanguagesModel {
    languages: UserLanguage[];
}

export const saveAccountSetup = (userId: string, dataForm: SaveBasicAssistantDataModel) => {
    return axios.default.put<void>(url(userId, 'accountSetup'), dataForm)
}

export const saveCustomerInvoicingMode = (userId: string, mode: CustomerInvoicingMode) => {
    return axios.default.put<void>(url(userId, 'customerInvoicingMode'), { data: mode })
}

export const saveLanguages = (userId: string, model: SaveLanguagesModel) => {
    return axios.default.put<void>(url(userId, 'languages'), model)
}
