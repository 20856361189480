import * as axios from 'axios';

const url = `${process.env.VUE_APP_API_URL}/categories`;

export interface CategoryGroup {
    id: string,
    name: string;
    categories: Category[];
    serviceType: ServiceType;
    order: number;
}

export interface Category {
    id: string;
    name: string;
    verifiedBadge: string;
}

export enum ServiceType {
    Online = 0,
    Offline = 1
}

export interface PageCategoryListModel
{
    onlineGroups: PageCategoryGroupModel[];
    offlineGroups: PageCategoryGroupModel[];
}

export interface PageCategoryGroupModel
{
    categories: PageCategoryModel[];
    id: string;
    name: string;
    order: number;
    additionalLabelLink: string;
    additionalLabel: string;
}

export interface PageCategoryModel
{
    id: string;
    name: string;
    description: string;
    numberOfAssistants: number;
}

export function fetchCategories() {
    return axios.default.get<CategoryGroup[]>(`${url}`);
}

export function fetchPageCategories() {
    return axios.default.get<PageCategoryListModel>(`${url}/page`);
}

